import React from 'react';
import Footer from "../components/footer/Footer";

function PrivacyPolicy() {
    return (
        <div className="spotmonitor-terms-and-conditions subpage">
            <div className="body-copy">
                <h1>Általános Szerződési Feltételek</h1>
                <p>Az alkalmazás letöltésével vagy használatával ezek a feltételek automatikusan vonatkoznak Önre – ezért gondosan olvassa el őket az alkalmazás használata előtt.
                    Semmilyen módon nem másolhatja vagy módosíthatja az alkalmazást, az alkalmazás bármely részét vagy védjegyeinket. Nem próbálhatja ki az alkalmazás forráskódját, és ne próbálja meg lefordítani az alkalmazást más nyelvekre vagy származékos verziókat készíteni.</p>

                <p>A SpotMonitor elkötelezett amellett, hogy az alkalmazás a lehető leghasznosabb és leghatékonyabb legyen. Emiatt a SpotMonitor fenntartja a jogot, hogy bármikor és bármilyen okból módosítsa az alkalmazást, vagy díjat számítson fel a szolgáltatásaiért.
                    A SpotMonitor soha nem számít fel Önnek díjat az alkalmazásért vagy annak szolgáltatásaiért anélkül, hogy világossá tenné, hogy pontosan mire is fizet.</p>

                <p>A SpotMonitor alkalmazás tárolja és feldolgozza az Ön által számunkra megadott személyes adatokat a Szolgáltatásunk nyújtása érdekében. Az Ön felelőssége, hogy biztonságban tartsa telefonját és az alkalmazáshoz való hozzáférést. Ezért azt javasoljuk, hogy ne jailbreakelje vagy rootolja a telefont,
                    ami az eszköz hivatalos operációs rendszere által előírt szoftverkorlátozások és korlátozások eltávolításának folyamata. Ez sebezhetővé teheti telefonját rosszindulatú programokkal/vírusokkal/rosszindulatú programokkal szemben, veszélyeztetheti telefonja biztonsági funkcióit, és azt jelentheti,
                    hogy a SpotMonitor alkalmazás nem fog megfelelően vagy egyáltalán nem fog működni.</p>

                <p>Tudnia kell, hogy vannak bizonyos dolgok, amelyekért a SpotMonitor nem vállal felelősséget. Az alkalmazás bizonyos funkcióihoz aktív internetkapcsolat szükséges. A kapcsolat lehet Wi-Fi vagy a mobilhálózat szolgáltatója, de nem tudunk felelősséget vállalni azért, hogy az alkalmazás nem működik teljes funkcionalitással,
                    ha nem fér hozzá a Wi-Fi-hez, és nem rendelkezik elegendő adatkerettel.</p>

                <p>Ha Wi-Fi-vel rendelkező területen kívül használja az alkalmazást, ne feledje, hogy a mobilhálózat-szolgáltatóval kötött szerződés feltételei továbbra is érvényesek. Ennek eredményeként mobilszolgáltatója felszámíthatja az adatszolgáltatás díját az alkalmazás elérése közbeni kapcsolat időtartamára, vagy egyéb harmadik féltől származó díjat.
                    Az alkalmazás használata során felelősséget vállal minden ilyen díjért, beleértve a barangolási adatátviteli díjakat is, ha az alkalmazást saját területén (azaz régión vagy országon) kívül használja anélkül, hogy kikapcsolná az adatbarangolást.
                    Ha nem Ön a számla kifizetője annak az eszköznek, amelyen az alkalmazást használja, kérjük, vegye figyelembe, hogy feltételezzük, hogy engedélyt kapott a számlafizetőtől az alkalmazás használatára.</p>

                <p>Ugyanígy a SpotMonitor nem mindig tud felelősséget vállalni az alkalmazás használatáért, azaz gondoskodnia kell arról, hogy az eszköz folyamatosan töltve legyen – ha lemerül az akkumulátor, és nem tudja bekapcsolni a szolgáltatás igénybevételéhez, a SpotMonitor nem tudja vállalja a felelősséget.</p>

                <p>Tekintettel az alkalmazás Ön általi használatáért vállalt felelősségünkre, az alkalmazás használata során fontos észben tartani, hogy bár törekszünk annak biztosítására, hogy az mindig frissítve és helyesen legyen, harmadik felekre bízzuk a információkat, hogy azokat az Ön rendelkezésére bocsássuk.
                    A SpotMonitor nem vállal felelősséget semmilyen közvetlen vagy közvetett veszteségért, amelyet az alkalmazás ezen funkcióira való teljes támaszkodás következtében tapasztal.</p>

                <p>Előfordulhat, hogy valamikor frissíteni szeretnénk az alkalmazást. Az alkalmazás jelenleg Android és iOS rendszeren érhető el – a követelmények mindkét rendszerre (és minden további rendszerre, amelyre kiterjesztjük az alkalmazás elérhetőségét) változhatnak, és le kell töltenie a frissítéseket, ha meg szeretné tartani az alkalmazás használatával.
                    A SpotMonitor nem ígéri, hogy mindig frissíti az alkalmazást, hogy az releváns legyen az Ön számára, és/vagy működjön a készülékére telepített Android és iOS verzióval. Ön azonban megígéri, hogy mindig elfogadja az alkalmazás frissítéseit, ha felajánlják Önnek. Lehetséges, hogy leállítjuk az alkalmazás biztosítását, és bármikor megszakíthatjuk a használatát anélkül,
                    hogy értesítést küldenénk a felmondásról. Hacsak másképp nem mondjuk, bármilyen felmondás esetén,
                    <br />(a) a jelen feltételekben Önnek biztosított jogok és licencek megszűnnek;
                    <br />(b) abba kell hagynia az alkalmazás használatát, és (ha szükséges) törölnie kell az eszközéről.</p>

                <h2>A jelen Általános Szerződési Feltételek változásai</h2>

                <p>Időről időre frissíthetjük Általános Szerződési Feltételeinket. Ezért javasoljuk, hogy rendszeresen ellenőrizze ezt az oldalt bármilyen változás miatt. Minden változásról az új Általános Szerződési Feltételek ezen az oldalon történő közzétételével értesítjük. Jelen feltételek 2023-06-01-től hatályosak.</p>

                <h2>Lépjen kapcsolatba velünk</h2>

                <p>Ha bármilyen kérdése vagy javaslata van az Általános Szerződési Feltételeinkkel kapcsolatban, ne habozzon kapcsolatba lépni velünk a bence.dragsits@drb.services címen.</p>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
