import React from 'react';
import {Link} from "react-router-dom";
import { FaPhone, FaEnvelope } from "react-icons/fa";

function Footer() {
    return (
        <div className="contact-info">
          <div className="copyright-row">Copyright &copy; 2023 <strong>SPOTMONITOR</strong></div>
          <div className="contact-row">
            <div className="fa-style email"><FaPhone /><a href="tel:+36205875533" className="email-address">+36205875533</a></div>
            <div className="fa-style"><FaEnvelope /><a href="mailto:bence.dragsits@drb.services">bence.dragsits@drb.services</a></div>
          </div>
          <div className="link-row">
              <Link to={'/terms-and-conditions'}>Terms and Conditions</Link> - <Link to={'/privacy-policy'}>Privacy Policy</Link>
          </div>
        </div>

    );
}

export default Footer;


