import React from 'react';
import Footer from "../components/footer/Footer";

function Home() {
    return (
        <div className="spotmonitor-home">
          <Footer />
        </div>
    );
}

export default Home;


