import React from 'react';
import Footer from "../components/footer/Footer";

function PrivacyPolicy() {
    return (
        <div className="spotmonitor-privacy-policy subpage">
            <div className="body-copy">
                <h1>Adatvédelmi Szabályzat</h1>
                <p>A SpotMonitor alkalmazás egy ingyenes alkalmazás. Ezt a SZOLGÁLTATÁST ingyenesen biztosítjuk, és úgy szánjuk, ahogy van.</p>

                <p>Ezt az oldalt arra használjuk, hogy tájékoztassuk a látogatókat a személyes adatok gyűjtésére, felhasználására és közzétételére vonatkozó irányelveinkről, ha valaki úgy dönt, hogy igénybe veszi Szolgáltatásunkat.
                    Ha úgy dönt, hogy igénybe veszi Szolgáltatásunkat, akkor elfogadja a jelen szabályzattal kapcsolatos információk gyűjtését és felhasználását. Az általunk gyűjtött Személyes adatokat a Szolgáltatás nyújtására és fejlesztésére használjuk fel.
                    Az Ön adatait nem használjuk fel és nem osztjuk meg senkivel, kivéve a jelen Adatvédelmi szabályzatban leírtakat.</p>

                <p>Az ebben az adatvédelmi szabályzatban használt kifejezések jelentése megegyezik a mi Általános Szerződési Feltételeinkben szereplő kifejezésekkel, amelyek a SpotMonitor webhelyen érhetők el, hacsak a jelen Adatvédelmi szabályzat másként nem rendelkezik.</p>

                <h2>Információgyűjtés és -felhasználás</h2>

                <p>A jobb élmény érdekében Szolgáltatásunk használata során megkövetelhetjük, hogy adjon meg számunkra bizonyos személyazonosításra alkalmas adatokat. Az általunk kért információkat megőrizzük, és a jelen adatvédelmi szabályzatban leírtak szerint használjuk fel. Az alkalmazás harmadik féltől származó szolgáltatásokat használ, amelyek az Ön azonosítására használt információkat gyűjthetnek.</p>

                <h2>Cookie-k</h2>

                <p>A sütik kis mennyiségű adatot tartalmazó fájlok, amelyeket általában névtelen egyedi azonosítóként használnak. Ezeket az Ön által felkeresett webhelyek küldik a böngészőjének, és az eszköz belső memóriájában tárolódnak.
                    Ez a Szolgáltatás kifejezetten nem használja ezeket a „sütiket”. Az alkalmazás azonban használhat harmadik féltől származó kódot és olyan könyvtárakat, amelyek „cookie-kat” használnak információk gyűjtésére és szolgáltatásaik fejlesztésére.
                    Lehetősége van arra, hogy elfogadja vagy elutasítsa ezeket a sütiket, és tudja, mikor küldenek cookie-t az Ön készülékére. Ha úgy dönt, hogy elutasítja a sütiket, előfordulhat, hogy nem fogja tudni használni a Szolgáltatás egyes részeit.
                </p>

                <h2>Szolgáltatók</h2>

                <p>A következő okok miatt alkalmazhatunk külső cégeket és magánszemélyeket: Szolgáltatásunk megkönnyítése érdekében;
                    a Szolgáltatás nyújtása a nevünkben;
                    Szolgáltatással kapcsolatos szolgáltatások végzésére;
                    vagy Hogy segítsen nekünk a Szolgáltatásunk használatának elemzésében.
                    Tájékoztatjuk a Szolgáltatás felhasználóit, hogy ezek a harmadik felek hozzáférhetnek személyes adataikhoz. Ennek oka az, hogy a rájuk bízott feladatokat a mi nevünkben végezzük el. Kötelesek azonban, hogy az információkat semmilyen más célra ne hozzák nyilvánosságra és ne használják fel.</p>

                <h2>Biztonság</h2>

                <p>Nagyra értékeljük személyes adatainak rendelkezésünkre bocsátása iránti bizalmát, ezért igyekszünk kereskedelmileg elfogadható eszközöket alkalmazni azok védelmére. De ne feledje, hogy az interneten keresztüli továbbítás vagy az elektronikus tárolás egyik módja sem 100%-ban biztonságos és megbízható, és nem tudjuk garantálni az abszolút biztonságot.</p>

                <h2>Más oldalakra mutató hivatkozások</h2>

                <p>Ez a szolgáltatás más webhelyekre mutató hivatkozásokat tartalmazhat. Ha rákattint egy harmadik fél linkjére, akkor az adott webhelyre irányítja át. Vegye figyelembe, hogy ezeket a külső oldalakat nem mi üzemeltetjük. Ezért nyomatékosan javasoljuk, hogy tekintse át ezen weboldalak adatvédelmi szabályzatát. Nincs ellenőrzésünk és nem vállalunk felelősséget harmadik felek webhelyeinek vagy szolgáltatásainak tartalma, adatvédelmi irányelvei vagy gyakorlata felett.</p>

                <h2>Gyermekek védelme</h2>

                <p>Ezek a szolgáltatások nem szólnak 13 év alatti személyeknek. Nem gyűjtünk tudatosan személyazonosításra alkalmas adatokat 13 év alatti gyermekektől. Ha azt észleljük, hogy egy 13 éven aluli gyermek személyes adatokat adott meg nekünk, azonnal töröljük ezeket szervereinkről. Ha Ön szülő vagy gyám, és tudatában van annak, hogy gyermeke személyes adatokat adott meg nekünk, kérjük, vegye fel velünk a kapcsolatot, hogy meg tudjuk tenni a szükséges lépéseket.</p>

                <h2>Jelen adatvédelmi szabályzat változásai</h2>

                <p>Időről időre frissíthetjük Adatvédelmi szabályzatunkat. Ezért javasoljuk, hogy rendszeresen ellenőrizze ezt az oldalt bármilyen változás miatt. Minden változásról az új adatvédelmi szabályzat közzétételével értesítjük ezen az oldalon.</p>

                <p>Ez a szabályzat 2023-06-01-től hatályos</p>

                <h2>Lépjen kapcsolatba velünk</h2>

                <p>Ha bármilyen kérdése vagy javaslata van az adatvédelmi szabályzatunkkal kapcsolatban, ne habozzon kapcsolatba lépni velünk a bence.dragsits@drb.services címen.</p>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
