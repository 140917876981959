import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import './App.css';
import Layout from "./components/Layout";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicyHU from "./pages/PrivacyPolicyHU";
import TermsAndConditionsHU from "./pages/TermsAndConditionsHU";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="adatvedelmi-szabalyzat" element={<PrivacyPolicyHU />} />
          <Route path="altalanos-szerzodesi-feltetelek" element={<TermsAndConditionsHU />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
