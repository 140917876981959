import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "./header/Header";

const Layout = () => {
    const location = useLocation();
    const path = location.pathname.substring(1);

    return (
        <div className={`${path} ${path ? 'subpage' : ''}`}>
            <Header />
            <Outlet />
        </div>
    );
};

export default Layout;