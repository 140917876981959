import React from 'react';
import logo from '../../assets/images/logo_spotmonitor_black.png';
import {Link} from "react-router-dom";

function Header() {
    return (
        <header className="spot-header">
            <Link to={'/'}><img src={logo} className="spot-logo" alt="logo" /></Link>
        </header>
    );
}

export default Header;


